import "./App.css";
import React, { useState, useEffect } from "react";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import { AppContext } from "./libs/contextLib";
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(null);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    console.log('onload')
    try {
      const user = await Auth.currentSession();
      console.log('user', user)
      userHasAuthenticated(user);
    }
    catch(e) {
    console.log('onload Error')

      if (e !== 'No current user') {
        console.error(e);
      }
    }

    
  }
  return (
    <Router>
      <div className="outer">
        <div className="inner">
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
            <Routes />
          </AppContext.Provider>
        </div>
      </div>
    </Router>
  );
}

export default App;
