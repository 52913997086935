import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";

import { useHistory } from "react-router-dom";
export const Home = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [fbKey, setfbKey] = useState("");
  const [status, setStatus] = useState(false);
  const [ytKey, setytKey] = useState("");

  const [fb, setfb] = useState("");
  const [yt, setyt] = useState("");
  const { isAuthenticated, userHasAuthenticated } = useAppContext();

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const { fbkey } = await loadFB();
        setfbKey(fbkey);
        const { ytkey } = await loadYT();
        setytKey(ytkey);
        checkStatus();
      } catch (e) {
        console.error(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  
  function buildCDN() {
    const myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: isAuthenticated.accessToken.jwtToken,
      },
    body: {
      "isdestroy": false
    },
    };
    API.post("keys", "/buildcdn", myInit);
  }

  function destroyCDN() {
    const myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: isAuthenticated.accessToken.jwtToken,
      },
    body: {
      "isdestroy": false
    },
    };
    API.post("keys", "/destroycdn", myInit);
  }

  
  function loadYT() {
    const myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: isAuthenticated.accessToken.jwtToken,
      },
    };

    return API.get("keys", "/getyt", myInit);
  }
  function loadFB() {
    const myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: isAuthenticated.accessToken.jwtToken,
      },
    };

    return API.get("keys", "/getfb", myInit);
  }
  async function checkStatus() {
    const myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: isAuthenticated.idToken.jwtToken,
      },
    };
    
    try {
      const { cdnStatus } = await API.get("keys", "/cdnstatus", myInit);
      //setStatus(cdnStatus ? true : false);

      setStatus(cdnStatus);
    } catch (e) {
      setStatus(false);
      console.error(e);
    }
  }
  const onLogoutClick = async () => {
    await Auth.signOut();

    userHasAuthenticated(null);

    history.push("/login");
  };
  const handleFBSubmit = async () => {
    if (fb.length > 0) {
      const myInit = {
        headers: {
          "Content-Type": "application/json",
          Authorization: isAuthenticated.accessToken.jwtToken,
        },
        body: {
          key: fb,
        },
      };

      const { fbkey } = await API.post("keys", "/fbkey", myInit);
      setfbKey(fbkey);
    }
  };
  const handleYTSubmit = async () => {
    if (yt.length > 0) {
      const myInit = {
        headers: {
          "Content-Type": "application/json",
          Authorization: isAuthenticated.accessToken.jwtToken,
        },
        body: {
          key: yt,
        },
      };

      const { ytkey } = await API.post("keys", "/ytkey", myInit);
      setytKey(ytkey);
    }
  };
  console.log({status})
  return (
    <div>
      <div className="text-right">
        <button className="btn btn-link" onClick={onLogoutClick}>
          Logout
        </button>
      </div>
      <h3>Iron Works Church CDN Control Center</h3>
      {!isLoading ? (
        <>
          <div className="form-group">
            <label>
              <span className="pr-2"> Your CDN status is</span>
              {status ? (
                <span className="badge badge-success">Online</span>
              ) : (
                <span className="badge badge-danger">Offline</span>
              )}
            </label>
            |
            <button className="btn btn-link text-primary font-weight-bold px-1" onClick={buildCDN}>
              Build 
            </button>
            <button className=" btn btn-link text-danger px-1" onClick={destroyCDN}>Destroy</button>
          </div>

          <div className="form-group">
            <label>Current YouTube Stream Key: </label>
            <span className="badge badge-pill badge-secondary p-3">
              {ytKey}
            </span>
          </div>

          <div className="form-group">
            <label>Current Facebook Stream Key: </label>
            <span className="badge badge-pill badge-secondary p-3">
              {fbKey}
            </span>
          </div>

          <div className="form-inline">
            <div className="form-group mb-2">
              <label htmlFor="yt">Update YouTube Stream Key:</label>
            </div>
            <div className="form-group mx-sm-3 mb-2">
              <input
                type="text"
                className="form-control"
                id="yt"
                value={yt}
                onChange={(e) => {
                  setyt(e.target.value);
                }}
                placeholder="YouTube Stream Key"
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary mb-2"
              onClick={handleYTSubmit}
            >
              Submit
            </button>
          </div>
          <div className="form-inline">
            <div className="form-group mb-2">
              <label htmlFor="fb">Update Facebook Stream Key:</label>
            </div>
            <div className="form-group mx-sm-3 mb-2">
              <input
                type="text"
                className="form-control"
                id="fb"
                value={fb}
                onChange={(e) => {
                  setfb(e.target.value);
                }}
                placeholder="Facebook Stream Key"
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary mb-2"
              onClick={handleFBSubmit}
            >
              Submit
            </button>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center m-5">
          <div className="spinner-grow div-loader"   role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};