const config = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://oapaaexrug.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Kjx3Gedtt",
    APP_CLIENT_ID: "5es8mqpsbh1oq4635rb32qk2o1",
    IDENTITY_POOL_ID: "us-east-1:49bda808-1a9b-4627-80ac-ba2f7c2028c2",
  },
};

export default config;
