import React , {useState} from 'react'
import { useAppContext } from '../libs/contextLib';
import { useFormFields } from './../libs/hooksLib';
import { Auth } from 'aws-amplify';

export const Login = () => {
    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
      username: "",
      password: ""
    });
  
    function validateForm() {
      return fields.username.length > 0 && fields.password.length > 0;
    }
  
    async function handleSubmit(event) {
        event.preventDefault();
    
        setIsLoading(true);
    
        try {
          await Auth.signIn(fields.username, fields.password);
          const user = await Auth.currentSession();
      console.log('user', user) 
          userHasAuthenticated(user);
        } catch (e) {
          console.error(e);
          setIsLoading(false);
        }
      }
    return (
        <form onSubmit={handleSubmit}>

        <h3>Log in</h3>

        <div className="form-group">
            <label>Username</label>
            <input autoFocus
            type="text"
            id="username"
            value={fields.username}
            onChange={handleFieldChange}
            className="form-control"
             placeholder="Enter username" />
        </div>

        <div className="form-group">
            <label>Password</label>
            <input type="password"
            id="password"
            value={fields.password}
            onChange={handleFieldChange}
            className="form-control"
            placeholder="Enter password" />
        </div>

        {/* <div className="form-group">
            <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
            </div>
        </div> */}

        <button type="submit" className="btn btn-dark btn-lg btn-block"
        
        disabled={!validateForm() || isLoading}
        >
         { isLoading ?  (<>
         <div className="spinner-border text-primary" role="status"></div>
         <span className="ml-3">Loading...</span>
         </>
            )
           : 'Sign in'}</button>
    </form>
    )
}
